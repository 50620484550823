var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"notifications"},[_c('vue-headful',{attrs:{"title":[_vm.$t('menu.notifications')]+'- UNDERMRKT',"description":"Description from vue-headful"}}),_c('div',{staticClass:"container"},[_c('h1',[_c('div',{staticClass:"icon"},[_c('font-awesome-icon',{attrs:{"icon":['fa','envelope']}})],1),_vm._v(_vm._s(_vm.$t("menu.notifications"))+" ")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"notifications__item"},[_c('div',{staticClass:"status"},[_vm._m(0),_c('div',{staticClass:"info"},[_c('div',{staticClass:"name"},[_vm._v("Telegram")]),_c('div',{staticClass:"descr"},[_vm._v(" "+_vm._s(_vm.$t("notifications.tg_message"))+" ")]),(_vm.user.profile.telegram != 'true')?_c('div',{staticClass:"instructions"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("notifications.instructions"))+":")]),_c('ul',[_c('li',[_vm._v("1. "+_vm._s(_vm.$t("notifications.open"))+" Telegram "),_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
  content: 'Open in new tab',
  show: _vm.showTooltip,
  trigger: 'hover',
  hideOnTargetClick:false,
  placement: 'top-center',
}),expression:"{\n  content: 'Open in new tab',\n  show: showTooltip,\n  trigger: 'hover',\n  hideOnTargetClick:false,\n  placement: 'top-center',\n}"}],attrs:{"href":"https://t.me/undermrkt_bot","target":"_blank"}},[_c('strong',[_vm._v("@undermrkt_bot")])])]),_c('li',[_vm._v(" 2. "+_vm._s(_vm.$t("notifications.type"))+" "),_c('strong',[_vm._v("/start")])]),_c('li',[_vm._v("3. "+_vm._s(_vm.$t("notifications.paste"))+" ("),_c('strong',{directives:[{name:"clipboard",rawName:"v-clipboard",value:(_vm.user.profile.user_id),expression:"user.profile.user_id"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.onCopy),expression:"onCopy",arg:"success"},{name:"tooltip",rawName:"v-tooltip",value:({
  content: _vm.tip.copyId[_vm.$i18n.locale],
  show: _vm.showTooltip,
  trigger: 'hover',
  hideOnTargetClick:false,
  placement: 'top-center',
}),expression:"{\n  content: tip.copyId[$i18n.locale],\n  show: showTooltip,\n  trigger: 'hover',\n  hideOnTargetClick:false,\n  placement: 'top-center',\n}"}],staticClass:"user-id",on:{"mouseleave":_vm.originalTip}},[_vm._v(_vm._s(_vm.user.profile.user_id))]),_vm._v(") "+_vm._s(_vm.$t("notifications.paste_2")))])])]):_c('div',{staticClass:"disable-insturction"},[_vm._v(_vm._s(_vm.$t("notifications.disable_instructions"))+" "),_c('strong',[_vm._v("/stop")])])]),_c('div',{staticClass:"status-label",class:{active:_vm.user.profile.telegram == 'true'}},[(_vm.user.profile.telegram == 'true')?_c('span',[_vm._v(_vm._s(_vm.$t("sidebar.active")))]):_c('span',[_vm._v(_vm._s(_vm.$t("sidebar.disabled")))])])])]),(1<0)?_c('div',{staticClass:"notifications__item"},[_c('div',{staticClass:"status"},[_vm._m(1),_c('div',{staticClass:"info"},[_c('div',{staticClass:"name"},[_vm._v("Facebook Messenger")]),_c('div',{staticClass:"descr"},[_vm._v(" "+_vm._s(_vm.$t("notifications.fb_message"))+" ")]),(_vm.user.profile.telegram != 'true')?_c('div',{staticClass:"instructions"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("notifications.instructions"))+":")]),_c('ul',[_c('li',[_vm._v("1. "+_vm._s(_vm.$t("notifications.open"))+" Facebook "),_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
  content: 'Open in new tab',
  show: _vm.showTooltip,
  trigger: 'hover',
  hideOnTargetClick:false,
  placement: 'top-center',
}),expression:"{\n  content: 'Open in new tab',\n  show: showTooltip,\n  trigger: 'hover',\n  hideOnTargetClick:false,\n  placement: 'top-center',\n}"}],attrs:{"href":"http://m.me/undermrkt","target":"_blank"}},[_c('strong',[_vm._v("@undermrkt")])])]),_c('li',[_vm._v(" 2. "+_vm._s(_vm.$t("notifications.type"))+" "),_c('strong',[_vm._v("/start")])]),_c('li',[_vm._v("3. "+_vm._s(_vm.$t("notifications.paste"))+" ("),_c('strong',{directives:[{name:"clipboard",rawName:"v-clipboard",value:(_vm.user.profile.user_id),expression:"user.profile.user_id"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.onCopy),expression:"onCopy",arg:"success"},{name:"tooltip",rawName:"v-tooltip",value:({
  content: _vm.tip.copyId[_vm.$i18n.locale],
  show: _vm.showTooltip,
  trigger: 'hover',
  hideOnTargetClick:false,
  placement: 'top-center',
}),expression:"{\n  content: tip.copyId[$i18n.locale],\n  show: showTooltip,\n  trigger: 'hover',\n  hideOnTargetClick:false,\n  placement: 'top-center',\n}"}],staticClass:"user-id",on:{"mouseleave":_vm.originalTip}},[_vm._v(_vm._s(_vm.user.profile.user_id))]),_vm._v(") "+_vm._s(_vm.$t("notifications.paste_2")))])])]):_c('div',{staticClass:"disable-insturction"},[_vm._v(_vm._s(_vm.$t("notifications.disable_instructions"))+" "),_c('strong',[_vm._v("/stop")])])]),_c('div',{staticClass:"status-label",class:{active:_vm.user.profile.facebook == 'true'}},[(_vm.user.profile.facebook == 'true')?_c('span',[_vm._v(_vm._s(_vm.$t("sidebar.active")))]):_c('span',[_vm._v(_vm._s(_vm.$t("sidebar.disabled")))])])])]):_vm._e()])]),_c('sidebar',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t("menu.notifications")))]},proxy:true},{key:"text",fn:function(){return [_vm._v(_vm._s(_vm.$t("notifications.description")))]},proxy:true}])})],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"channel-icon"},[_c('img',{attrs:{"src":require("@/assets/telegram.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"channel-icon"},[_c('img',{attrs:{"src":require("@/assets/messenger.svg"),"alt":""}})])
}]

export { render, staticRenderFns }