<template>
    <div class="notifications">
        <vue-headful :title="[$t('menu.notifications')]+'- UNDERMRKT'" description="Description from vue-headful" />
        <div class="container">
            <h1>
                <div class="icon">
                    <font-awesome-icon :icon="['fa','envelope']" />
                </div>{{ $t("menu.notifications") }}
            </h1>
            <div class="row">
                <div class="content">
                    <div class="card">
                        <div class="notifications__item">
                            <div class="status">
                                <div class="channel-icon">
                                    <img src="@/assets/telegram.svg" alt="">
                                </div>
                                <div class="info">
                                    <div class="name">Telegram</div>
                                    <div class="descr">
                                        {{ $t("notifications.tg_message") }}
                                    </div>
                                    <div class="instructions" v-if="user.profile.telegram != 'true'">
                                        <div class="title">{{ $t("notifications.instructions") }}:</div>
                                        <ul>
                                            <li>1. {{ $t("notifications.open") }} Telegram <a href="https://t.me/undermrkt_bot" target="_blank" v-tooltip="{
      content: 'Open in new tab',
      show: showTooltip,
      trigger: 'hover',
      hideOnTargetClick:false,
      placement: 'top-center',
    }"><strong>@undermrkt_bot</strong></a></li>
                                            <li>
                                                2. {{ $t("notifications.type") }} <strong>/start</strong>
                                            </li>
                                            <li>3. {{ $t("notifications.paste") }} (<strong v-clipboard="user.profile.user_id" v-clipboard:success="onCopy" v-on:mouseleave="originalTip" v-tooltip="{
      content: tip.copyId[$i18n.locale],
      show: showTooltip,
      trigger: 'hover',
      hideOnTargetClick:false,
      placement: 'top-center',
    }" class="user-id">{{user.profile.user_id}}</strong>) {{ $t("notifications.paste_2") }}</li>
                                        </ul>
                                    </div>
                                        <div v-else class="disable-insturction">{{ $t("notifications.disable_instructions") }} <strong>/stop</strong></div>
                                </div>
                                <div class="status-label" :class="{active:user.profile.telegram == 'true'}">
                                    <span v-if="user.profile.telegram == 'true'">{{ $t("sidebar.active") }}</span>
                                    <span v-else>{{ $t("sidebar.disabled") }}</span>
                                </div>
                            </div>
                        </div>
                        <!-- /item -->
                        <div class="notifications__item" v-if="1<0">
                            <div class="status">
                                <div class="channel-icon">
                                    <img src="@/assets/messenger.svg" alt="">
                                </div>
                                <div class="info">
                                    <div class="name">Facebook Messenger</div>
                                    <div class="descr">
                                        {{ $t("notifications.fb_message") }}
                                    </div>
                                    <div class="instructions" v-if="user.profile.telegram != 'true'">
                                        <div class="title">{{ $t("notifications.instructions") }}:</div>
                                        <ul>
                                            <li>1. {{ $t("notifications.open") }} Facebook <a href="http://m.me/undermrkt" target="_blank" v-tooltip="{
      content: 'Open in new tab',
      show: showTooltip,
      trigger: 'hover',
      hideOnTargetClick:false,
      placement: 'top-center',
    }"><strong>@undermrkt</strong></a></li>
                                            <li>
                                                2. {{ $t("notifications.type") }} <strong>/start</strong>
                                            </li>
                                            <li>3. {{ $t("notifications.paste") }} (<strong v-clipboard="user.profile.user_id" v-clipboard:success="onCopy" v-on:mouseleave="originalTip" v-tooltip="{
      content: tip.copyId[$i18n.locale],
      show: showTooltip,
      trigger: 'hover',
      hideOnTargetClick:false,
      placement: 'top-center',
    }" class="user-id">{{user.profile.user_id}}</strong>) {{ $t("notifications.paste_2") }}</li>
                                        </ul>
                                    </div>
                                    <div v-else class="disable-insturction">{{ $t("notifications.disable_instructions") }} <strong>/stop</strong></div>
                                </div>
                                <div class="status-label" :class="{active:user.profile.facebook == 'true'}">
                                    <span v-if="user.profile.facebook == 'true'">{{ $t("sidebar.active") }}</span>
                                    <span v-else>{{ $t("sidebar.disabled") }}</span>
                                </div>
                            </div>
                        </div>
                        <!-- /item -->
                    </div>
                </div>
                <!-- /content -->
                <sidebar>
                    <template v-slot:title>{{ $t("menu.notifications") }}</template>
                    <template v-slot:text>{{ $t("notifications.description") }}</template>
                </sidebar>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import sidebar from '@/components/Sidebar.vue';
export default {
    name: 'Notificatiobs',
    data() {
        return {
            tip: {
                copyId: {
                    en: 'Copy Your user ID to clipboard',
                    ru: 'Копировать ID в буфер'
                },
                copyIdMatrix: {
                    en: 'Copy Your user ID to clipboard',
                    ru: 'Копировать ID в буфер'
                },
                copyIdCopied: {
                    en: 'Copied!',
                    ru: 'Скопировано!'
                }
            },
            showTooltip: false
        }
    },
    components: {
        sidebar
    },
    computed: {
        // map `this.user` to `this.$store.getters.user`
        ...mapGetters({
            user: "user",
            plans: "plans",
            userSellers: "userSellers",
            userKeywords: "userKeywords"
        }),
    },
    methods: {
        onCopy: function() {
            this.tip.copyId[this.$i18n.locale] = this.tip.copyIdCopied[this.$i18n.locale];
        },
        originalTip: function() {
            setTimeout(() => {
                this.tip.copyId[this.$i18n.locale] =  this.tip.copyIdMatrix[this.$i18n.locale];
            }, 300);
        }
    },
    beforeCreate: function() {
        document.body.className = 'notifications';
    }
}
</script>
<style lang="scss" scoped>
.icon {
    background-color: #DB3741 !important;
}
.disable-insturction {
     margin-top: 10px;
                    padding: 6px 8px;
                    background-color: rgba(#3FAFF1, 0.2);
                    border-radius: 6px;
                    margin-bottom: 20px;
                    color: #136ca0;
}
.notifications {
    &__item {
        border-bottom: 1px solid #e7e7e7;
        min-height: 100px;
        padding-top: 30px;

        .status {
            display: flex;
            justify-content: space-between;

            .info {
                .name {
                    color: #606060;
                    font-size: 20px;
                    font-weight: 600;
                }

                .instructions {
                    margin-top: 10px;
                    padding: 6px 8px;
                    background-color: rgba(#3FAFF1, 0.2);
                    border-radius: 6px;
                    margin-bottom: 20px;
                    color: #136ca0;

                    a {
                        color: #136ca0;
                    }

                    &.viber {
                        background-color: rgba(101, 91, 172, 0.15);
                        color: #655bac;

                        a {
                            color: #655bac;
                        }

                    }

                    ul {
                        padding: 0px;
                        margin: 0px;
                        list-style: none;

                        li {
                            font-size: 13px;

                            margin-bottom: 5px;

                            .user-id {
                                cursor: pointer;
                            }


                        }

                        margin-bottom: 5px;
                    }

                    .title {
                        font-size: 13px;
                        margin-bottom: 6px;
                        display: block;
                        font-weight: bold;
                    }
                }

                .descr {
                    color: lighten(#606060, 20%);
                    font-size: 16px;
                    margin-top: 5px;

                    a {
                        color: #3FAFF1;
                    }
                }
            }

            .channel-icon {
                padding: 30px 30px;
                padding-top: 0px;
                display: flex;

                img {
                    height: 48px;
                }
            }

            .status-label {
                margin-left: auto;
                border-radius: 6px;
                width: 86px;
                height: 24px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                text-transform: uppercase;
                background: #D7D7D7;
                color: #938F8F;
                font-size: 14px;
                margin-right: 20px;

                &.active {
                    color: #0E7418;
                    background: #CDFDCC;
                }

                &.error {
                    background: #FDCCCC;
                    color: #D44949;
                }

            }
        }


        &:last-child {
            border: none;
        }
    }
}
</style>